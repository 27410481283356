/**
 * Toolkit styles
 */

body {
  font-family: sans-serif;
}

@import "./variables";
@import "./fonts";
@import "./anchorlist";
@import "./spacing";
@import "./helper";
@import "./grid-customized";
@import "./section";
@import "./icons";
@import "./container";
@import "./preisanpassungsbox";
