.vffwb-icon {
  display: block;
  font-size: 2.2rem;
  text-indent: -9999px;
}

.vffwb-icon-sm {
  width: 22px;
  height: 22px;
  background-size: 22px 22px!important;
}

.vffwb-icon-md {
  width: 34px;
  height: 34px;
  background-size: 34px 34px!important;
}

.vffwb-icon-absolute {
  position: absolute;
  right: 12px;
  top: 20px;
}

.vffwb-icon-relative {
  position: relative;
}

.vffwb-icon-arrow-down {
  background: url(../svg/arrow-bottom.svg);
  background-size: 22px 22px;
}

.vffwb-icon-plus {
  background: url(../svg/plus.svg);
  background-size: 22px 22px;
}

.vffwb-icon-equal {
  background: url(../svg/equal.svg);
  background-size: 22px 22px;
}

.vffwb-icon-obelus {
  background: url(../svg/obelus.svg);
  background-size: 22px 22px;
}
