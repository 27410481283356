

.vffwb-text-middle {
  text-align: center;
}

.vffwb-mb-xl {
  margin-bottom: 32px;
}

.vffwb-bg-color-gray {
  background-color: #E1E1E1;
}


.vffwb-anchor-list ul {
    column-gap: 24px;
    margin: 0;
    padding: 0;
    width: 100%;
 }
  @media only screen and (min-width: 768px) {
    .vffwb-anchor-list ul {
      column-count: 2;
   }
 }
  @media only screen and (min-width: 1024px) {
    .vffwb-anchor-list ul {
      column-count: 3;
   }
 }
  .vffwb-anchor-list ul li {
    margin: 0;
    margin-bottom: -2px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    list-style: none;
 }
  .vffwb-anchor-list ul a {
    display: block;
    position: relative;
    font-family: "Vattenfall Hall Medium","Helvetica Neue",Arial,sans-serif;
    transition: all .3s ease 0s;
    color: #000;
    text-decoration: none;
    border-bottom: 2px solid #edf1f6;
    border-top: 2px solid #edf1f6;
    padding-right: 44px;
    column-break-after: none;
    display: inline-block;
    width: 100%;
 }
  .vffwb-anchor-list ul a:hover {
    color: #000;
    background-color: #edf1f6;
    text-decoration: none;
 }
  .vffwb-anchor-list ul a:focus {
    outline: 0;
 }
  .vffwb-anchor-list ul a .icon {
    position: absolute;
    right: 12px;
    top: 20px;
    font-size: 2.2rem;
 }
