$grid-columns:         12;

$grid-gutter-width:    14px; // default for mobile (< 768px)
$grid-gutter-width-md: 24px; // >= 768px
$grid-gutter-width-xl: 30px; // >= 1200px

$grid-gutter-outside-width:    20px;
$grid-gutter-outside-width-md: 40px;
$grid-gutter-outside-width-xl: 51px;


// Breakpoints
$mobile-size:     320px; // sm
$tablet-size:     768px; // md
$tablet-size-ls: 1024px; // lg
$desktop-size:   1200px; // xl
$max-size:       1600px;

$sc-desktop-large-size: 1680px;

$grid-breakpoints: (
        xs: 0,
        sm: 320px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 320,
        md: 768,
        lg: 1024,
        xl: 1600px
) !default;

$color-black:            #000000; // rgb(0, 0, 0)
$color-white:            #FFFFFF; // rgb(255, 255, 255)
$color-yellow:           #FFDA00; // rgb(255, 218, 0)
$color-blue:             #2071B5; // rgb(32, 113, 181)
$color-grey:             #4E4B48; // rgb(78, 75, 72)
$color-dark-green:       #005C63; // rgb(0, 92, 99)
$color-dark-blue:        #1E324F; // rgb(30, 50, 79)
$color-pink:             #D1266B; // rgb(209, 38, 107)
$color-green:            #3DC07C; // rgb(61, 192, 124)
$color-red:              #F93B18; // rgb(249, 59, 24)
$color-purple:           #9B62C3; // rgb(155, 98, 195)
$color-dark-purple:      #85254B; // rgb(133, 37, 75)

// classes for brand colors
.vffwb-color-yellow       { color: $color-yellow; }
.vffwb-color-blue         { color: $color-blue; }
.vffwb-color-grey         { color: $color-grey; }
.vffwb-color-black        { color: $color-black; }
.vffwb-color-white        { color: $color-white; }

// classes for secondary colors
.vffwb-color-dark-green   { color: $color-dark-green; }
.vffwb-color-dark-blue    { color: $color-dark-blue; }
.vffwb-color-pink         { color: $color-pink; }
.vffwb-color-green        { color: $color-green; }
.vffwb-color-red          { color: $color-red; }
.vffwb-color-purple       { color: $color-purple; }
.vffwb-color-dark-purple  { color: $color-dark-purple; }
