.vffwb-preisanpassungs-box {

  .vffwb-bubbles,
  .vffwb-bubble-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .vffwb-bubble-wrapper {
    width: 80%;
    max-width: 400px;
  }

  .vffwb-strong-wrapper {
    font-size: 14px;
    line-height: 16px;
  }

  .vffwb-operation {
    margin: 10px 0;
  }

  .vffwb-bubble {
    position: relative;
    width: 100%;
    text-align: center;

    border: solid 6px $color-black;
    border-radius: 100px;
    box-sizing: border-box;

    padding: 12px;

    .vffwb-content {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: center;
    }

    &.vffwb-fill {
      color: $color-white;
    }

    &.vffwb-pink {
      border-color: $color-pink;

      &.vffwb-fill {
        background-color: $color-pink;
      }
    }

    &.vffwb-dark-green {
      border-color: $color-dark-green;

      &.vffwb-fill {
        background-color: $color-dark-green;
      }
    }

    &.vffwb-dark-blue {
      border-color: $color-dark-blue;

      &.vffwb-fill {
        background-color: $color-dark-blue;
      }
    }

    &.vffwb-green {
      border-color: $color-green;

      &.vffwb-fill {
        background-color: $color-green;
      }
    }

    &.vffwb-red {
      border-color: $color-red;

      &.vffwb-fill {
        background-color: $color-red;
      }
    }

    &.vffwb-purple {
      border-color: $color-purple;

      &.vffwb-fill {
        background-color: $color-purple;
      }
    }

    &.vffwb-dark-purple {
      border-color: $color-dark-purple;

      &.vffwb-fill {
        background-color: $color-dark-purple;
      }
    }
  }

  @media only screen and(min-width: $tablet-size) {
    .vffwb-two-cols {
      column-count: 2;
      column-gap: 34px;
    }
  }

  @media only screen and(min-width: $desktop-size) {
    .vffwb-bubbles,
    .vffwb-bubble-wrapper {
      flex-direction: row;
    }

    .vffwb-bubble-wrapper {
      width: auto;
      max-width: none;
    }

    .vffwb-operation {
      margin: 0 12px;
    }

    .vffwb-bubble {
      width: 149px;
      height: 149px;

      border-radius: 50%;
      border-width: 10px;

      .vffwb-content {
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
      }
    }
  }



  .vffwb-formular-toggle-area {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 80px;
    cursor: pointer;

    i {
      transition: transform .3s;
      font-size: 24px;
      margin-right: 12px;
    }

    i,
    a {
      display: flex;
    }

    &.vffwb-open i {
      transform: rotate(-90deg);
    }
  }

  .vffwb-formular-toggle-area:hover {
    i,
    a {
      color: $color-blue;
      transition: color .3s;
    }
  }

  &.vffwb-without-background .vffwb-formular-toggle-area{
    height: auto;
    background: none;
  }

  .vffwb-toggle-copy {
    height: 0;
    margin-bottom: 0;
    overflow-y: hidden;
    transition: height .3s;

    &.vffwb-open {
      margin-bottom: inherit;
    }
  }
  table td, table th, table.basic td, table.basic th {
    padding: 10px 12px;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
    td {
      min-width: 140px;
      text-align: right;
      background-color: rgba(0,0,0,0.05);
    }

    th {
      width: auto;
      text-align: right;
      font-family: $font-family-bold;
    }
  }

  @media screen and (min-width: $desktop-size) {
    table {
      td, th {
        width: 240px;
      }
    }
  }

  .vffwb-scroll-wrapper {
    width: 100%;
    overflow: hidden;
  }
}
