section {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
