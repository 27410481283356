.vffwb-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .vffwb-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .vffwb-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .vffwb-container {
    width: 1170px;
  }
}

.vffwb-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
