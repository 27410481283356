$font-family-display-bold: 'Vattenfall Hall Display Bold', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'Vattenfall Hall Display Bold';
  src: url('../fonts/VattenfallHallDisplay-Bold.eot') format('embedded-opentype'),
  url('../fonts/VattenfallHallDisplay-Bold.woff') format('woff'),
  url('../fonts/VattenfallHallDisplay-Bold.woff2') format('woff2'),
  url('../fonts/VattenfallHallDisplay-Bold.ttf') format('truetype');
}

$font-family-regular: 'Vattenfall Hall Regular', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'Vattenfall Hall Regular';
  src: url('../fonts/VattenfallHall-Regular.eot') format('embedded-opentype'),
  url('../fonts/VattenfallHall-Regular.woff') format('woff'),
  url('../fonts/VattenfallHall-Regular.woff2') format('woff2'),
  url('../fonts/VattenfallHall-Regular.ttf') format('truetype');
}

$font-family-medium: 'Vattenfall Hall Medium', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'Vattenfall Hall Medium';
  src: url('../fonts/VattenfallHall-Medium.eot') format('embedded-opentype'),
  url('../fonts/VattenfallHall-Medium.woff') format('woff'),
  url('../fonts/VattenfallHall-Medium.woff2') format('woff2'),
  url('../fonts/VattenfallHall-Medium.ttf') format('truetype');
}

$font-family-bold: 'Vattenfall Hall Bold', 'Helvetica Neue', Arial, sans-serif;
@font-face {
  font-family: 'Vattenfall Hall Bold';
  src: url('../fonts/VattenfallHall-Bold.eot') format('embedded-opentype'),
  url('../fonts/VattenfallHall-Bold.woff') format('woff'),
  url('../fonts/VattenfallHall-Bold.woff2') format('woff2'),
  url('../fonts/VattenfallHall-Bold.ttf') format('truetype');
}
