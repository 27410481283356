// grid customized for 3 different gutter widths:

// 1) default gutter width for mobile (< 768px / xs, sm)
// 2) gutter width for tablet (>= 768px / md, lg)
@media only screen and (min-width: $tablet-size) {
  .vffwb-container,
  .vffwb-container-fluid {
    padding-left: $grid-gutter-width-md / 2;
    padding-right: $grid-gutter-width-md / 2;
  }

  .vffwb-row {
    margin-left: - $grid-gutter-width-md / 2;
    margin-right: - $grid-gutter-width-md / 2;
  }

  .vffwb-col-1,
  .vffwb-col-2,
  .vffwb-col-3,
  .vffwb-col-4,
  .vffwb-col-5,
  .vffwb-col-6,
  .vffwb-col-7,
  .vffwb-col-8,
  .vffwb-col-9,
  .vffwb-col-10,
  .vffwb-col-11,
  .vffwb-col-12,
  .vffwb-col,
  .vffwb-col-auto,
  .vffwb-col-sm-1,
  .vffwb-col-sm-2,
  .vffwb-col-sm-3,
  .vffwb-col-sm-4,
  .vffwb-col-sm-5,
  .vffwb-col-sm-6,
  .vffwb-col-sm-7,
  .vffwb-col-sm-8,
  .vffwb-col-sm-9,
  .vffwb-col-sm-10,
  .vffwb-col-sm-11,
  .vffwb-col-sm-12,
  .vffwb-col-sm,
  .vffwb-col-sm-auto,
  .vffwb-col-md-1,
  .vffwb-col-md-2,
  .vffwb-col-md-3,
  .vffwb-col-md-4,
  .vffwb-col-md-5,
  .vffwb-col-md-6,
  .vffwb-col-md-7,
  .vffwb-col-md-8,
  .vffwb-col-md-9,
  .vffwb-col-md-10,
  .vffwb-col-md-11,
  .vffwb-col-md-12,
  .vffwb-col-md,
  .vffwb-col-md-auto,
  .vffwb-col-lg-1,
  .vffwb-col-lg-2,
  .vffwb-col-lg-3,
  .vffwb-col-lg-4,
  .vffwb-col-lg-5,
  .vffwb-col-lg-6,
  .vffwb-col-lg-7,
  .vffwb-col-lg-8,
  .vffwb-col-lg-9,
  .vffwb-col-lg-10,
  .vffwb-col-lg-11,
  .vffwb-col-lg-12,
  .vffwb-col-lg,
  .vffwb-col-lg-auto,
  .vffwb-col-xl-1,
  .vffwb-col-xl-2,
  .vffwb-col-xl-3,
  .vffwb-col-xl-4,
  .vffwb-col-xl-5,
  .vffwb-col-xl-6,
  .vffwb-col-xl-7,
  .vffwb-col-xl-8,
  .vffwb-col-xl-9,
  .vffwb-col-xl-10,
  .vffwb-col-xl-11,
  .vffwb-col-xl-12,
  .vffwb-col-xl,
  .vffwb-col-xl-auto {
    padding-left: $grid-gutter-width-md / 2;
    padding-right: $grid-gutter-width-md / 2;
  }
}

// 3) gutter width for tablet (>= 1200px / xl)
@media only screen and (min-width: $desktop-size) {
  .vffwb-container,
  .vffwb-container-fluid {
    padding-left: $grid-gutter-width-xl / 2;
    padding-right: $grid-gutter-width-xl / 2;
  }

  .vffwb-row {
    margin-left: - $grid-gutter-width-xl / 2;
    margin-right: - $grid-gutter-width-xl / 2;
  }

  .vffwb-col-1,
  .vffwb-col-2,
  .vffwb-col-3,
  .vffwb-col-4,
  .vffwb-col-5,
  .vffwb-col-6,
  .vffwb-col-7,
  .vffwb-col-8,
  .vffwb-col-9,
  .vffwb-col-10,
  .vffwb-col-11,
  .vffwb-col-12,
  .vffwb-col,
  .vffwb-col-auto,
  .vffwb-col-sm-1,
  .vffwb-col-sm-2,
  .vffwb-col-sm-3,
  .vffwb-col-sm-4,
  .vffwb-col-sm-5,
  .vffwb-col-sm-6,
  .vffwb-col-sm-7,
  .vffwb-col-sm-8,
  .vffwb-col-sm-9,
  .vffwb-col-sm-10,
  .vffwb-col-sm-11,
  .vffwb-col-sm-12,
  .vffwb-col-sm,
  .vffwb-col-sm-auto,
  .vffwb-col-md-1,
  .vffwb-col-md-2,
  .vffwb-col-md-3,
  .vffwb-col-md-4,
  .vffwb-col-md-5,
  .vffwb-col-md-6,
  .vffwb-col-md-7,
  .vffwb-col-md-8,
  .vffwb-col-md-9,
  .vffwb-col-md-10,
  .vffwb-col-md-11,
  .vffwb-col-md-12,
  .vffwb-col-md,
  .vffwb-col-md-auto,
  .vffwb-col-lg-1,
  .vffwb-col-lg-2,
  .vffwb-col-lg-3,
  .vffwb-col-lg-4,
  .vffwb-col-lg-5,
  .vffwb-col-lg-6,
  .vffwb-col-lg-7,
  .vffwb-col-lg-8,
  .vffwb-col-lg-9,
  .vffwb-col-lg-10,
  .vffwb-col-lg-11,
  .vffwb-col-lg-12,
  .vffwb-col-lg,
  .vffwb-col-lg-auto,
  .vffwb-col-xl-1,
  .vffwb-col-xl-2,
  .vffwb-col-xl-3,
  .vffwb-col-xl-4,
  .vffwb-col-xl-5,
  .vffwb-col-xl-6,
  .vffwb-col-xl-7,
  .vffwb-col-xl-8,
  .vffwb-col-xl-9,
  .vffwb-col-xl-10,
  .vffwb-col-xl-11,
  .vffwb-col-xl-12,
  .vffwb-col-xl,
  .vffwb-col-xl-auto {
    padding-left: $grid-gutter-width-xl / 2;
    padding-right: $grid-gutter-width-xl / 2;
  }
}

.vffwb-container-1000 {
  max-width: 1000px;
  margin: 0 auto;
}
