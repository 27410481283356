$mp-xs-mobile: 12px;
$mp-xs-tablet: 12px; // same as mobile, not used
$mp-xs-desktop: 12px; // same as mobile, not used
$mp-s-mobile: 24px;
$mp-s-tablet: 24px; // same as mobile, not used
$mp-s-desktop: 24px; // same as mobile, not used
$mp-m-mobile: 24px;
$mp-m-tablet: 32px;
$mp-m-desktop: 32px; // same as tablet, not used
$mp-l-mobile: 32px;
$mp-l-tablet: 32px; // same as mobile, not used
$mp-l-desktop: 40px;
$mp-xl-mobile: 32px;
$mp-xl-tablet: 48px;
$mp-xl-desktop: 64px;
$mp-xxl-mobile: 60px;
$mp-xxl-tablet: 80px;
$mp-xxl-desktop: 120px;
// margin, padding 0
.vffwb-p-0 {
  padding: 0;
}

.vffwb-pt-0 {
  padding-top: 0;
}

.vffwb-pb-0 {
  padding-bottom: 0;
}

.vffwb-pl-0 {
  padding-left: 0;
}

.vffwb-pr-0 {
  padding-right: 0;
}

.vffwb-m-0 {
  margin: 0;
}

.vffwb-mt-0 {
  margin-top: 0;
}

.vffwb-mb-0 {
  margin-bottom: 0;
}

.vffwb-ml-0 {
  margin-left: 0;
}

.vffwb-mr-0 {
  margin-right: 0;
}
// margin, padding XS
.vffwb-p-xs {
  padding: $mp-xs-mobile;
}

.vffwb-pt-xs {
  padding-top: $mp-xs-mobile;
}

.vffwb-pb-xs {
  padding-bottom: $mp-xs-mobile;
}

.vffwb-pl-xs {
  padding-left: $mp-xs-mobile;
}

.vffwb-pr-xs {
  padding-right: $mp-xs-mobile;
}

.vffwb-m-xs {
  margin: $mp-xs-mobile;
}

.vffwb-mt-xs {
  margin-top: $mp-xs-mobile;
}

.vffwb-mb-xs {
  margin-bottom: $mp-xs-mobile;
}

.vffwb-ml-xs {
  margin-left: $mp-xs-mobile;
}

.vffwb-mr-xs {
  margin-right: $mp-xs-mobile;
}
// margin, padding S
.vffwb-p-s {
  padding: $mp-s-mobile;
}

.vffwb-pt-s {
  padding-top: $mp-s-mobile;
}

.vffwb-pb-s {
  padding-bottom: $mp-s-mobile;
}

.vffwb-pl-s {
  padding-left: $mp-s-mobile;
}

.vffwb-pr-s {
  padding-right: $mp-s-mobile;
}

.vffwb-m-s {
  margin: $mp-s-mobile;
}

.vffwb-mt-s {
  margin-top: $mp-s-mobile;
}

.vffwb-mb-s {
  margin-bottom: $mp-s-mobile;
}

.vffwb-ml-s {
  margin-left: $mp-s-mobile;
}

.vffwb-mr-s {
  margin-right: $mp-s-mobile;
}
// margin, padding M
.vffwb-p-m {
  padding: $mp-m-mobile;
}

.vffwb-pt-m {
  padding-top: $mp-m-mobile;
}

.vffwb-pb-m {
  padding-bottom: $mp-m-mobile;
}

.vffwb-pl-m {
  padding-left: $mp-m-mobile;
}

.vffwb-pr-m {
  padding-right: $mp-m-mobile;
}

.vffwb-m-m {
  margin: $mp-m-mobile;
}

.vffwb-mt-m {
  margin-top: $mp-m-mobile;
}

.vffwb-mb-m {
  margin-bottom: $mp-m-mobile;
}

.vffwb-ml-m {
  margin-left: $mp-m-mobile;
}

.vffwb-mr-m {
  margin-right: $mp-m-mobile;
}

@media only screen and (min-width: $tablet-size) {
  .vffwb-p-m {
    padding: $mp-m-tablet;
  }

  .vffwb-pt-m {
    padding-top: $mp-m-tablet;
  }

  .vffwb-pb-m {
    padding-bottom: $mp-m-tablet;
  }

  .vffwb-pl-m {
    padding-left: $mp-m-tablet;
  }

  .vffwb-pr-m {
    padding-right: $mp-m-tablet;
  }

  .vffwb-m-m {
    margin: $mp-m-tablet;
  }

  .vffwb-mt-m {
    margin-top: $mp-m-tablet;
  }

  .vffwb-mb-m {
    margin-bottom: $mp-m-tablet;
  }

  .vffwb-ml-m {
    margin-left: $mp-m-tablet;
  }

  .vffwb-mr-m {
    margin-right: $mp-m-tablet;
  }
}
// margin, padding L
.vffwb-p-l {
  padding: $mp-l-mobile;
}

.vffwb-pt-l {
  padding-top: $mp-l-mobile;
}

.vffwb-pb-l {
  padding-bottom: $mp-l-mobile;
}

.vffwb-pl-l {
  padding-left: $mp-l-mobile;
}

.vffwb-pr-l {
  padding-right: $mp-l-mobile;
}

.vffwb-m-l {
  margin: $mp-l-mobile;
}

.vffwb-mt-l {
  margin-top: $mp-l-mobile;
}

.vffwb-mb-l {
  margin-bottom: $mp-l-mobile;
}

.vffwb-ml-l {
  margin-left: $mp-l-mobile;
}

.vffwb-mr-l {
  margin-right: $mp-l-mobile;
}

@media only screen and (min-width: $desktop-size) {
  .vffwb-p-l {
    padding: $mp-l-desktop;
  }

  .vffwb-pt-l {
    padding-top: $mp-l-desktop;
  }

  .vffwb-pb-l {
    padding-bottom: $mp-l-desktop;
  }

  .vffwb-pl-l {
    padding-left: $mp-l-desktop;
  }

  .vffwb-pr-l {
    padding-right: $mp-l-desktop;
  }

  .vffwb-m-l {
    margin: $mp-l-desktop;
  }

  .vffwb-mt-l {
    margin-top: $mp-l-desktop;
  }

  .vffwb-mb-l {
    margin-bottom: $mp-l-desktop;
  }

  .vffwb-ml-l {
    margin-left: $mp-l-desktop;
  }

  .vffwb-mr-l {
    margin-right: $mp-l-desktop;
  }
}
// margin, padding XL
.vffwb-p-xl {
  padding: $mp-xl-mobile;
}

.vffwb-pt-xl {
  padding-top: $mp-xl-mobile;
}

.vffwb-pb-xl {
  padding-bottom: $mp-xl-mobile;
}

.vffwb-pl-xl {
  padding-left: $mp-xl-mobile;
}

.vffwb-pr-xl {
  padding-right: $mp-xl-mobile;
}

.vffwb-m-xl {
  margin: $mp-xl-mobile;
}

.vffwb-mt-xl {
  margin-top: $mp-xl-mobile;
}

.vffwb-mb-xl {
  margin-bottom: $mp-xl-mobile;
}

.vffwb-ml-xl {
  margin-left: $mp-xl-mobile;
}

.vffwb-mr-xl {
  margin-right: $mp-xl-mobile;
}

@media only screen and (min-width: $tablet-size) {
  .vffwb-p-xl {
    padding: $mp-xl-tablet;
  }

  .vffwb-pt-xl {
    padding-top: $mp-xl-tablet;
  }

  .vffwb-pb-xl {
    padding-bottom: $mp-xl-tablet;
  }

  .vffwb-pl-xl {
    padding-left: $mp-xl-tablet;
  }

  .vffwb-pr-xl {
    padding-right: $mp-xl-tablet;
  }

  .vffwb-m-xl {
    margin: $mp-xl-tablet;
  }

  .vffwb-mt-xl {
    margin-top: $mp-xl-tablet;
  }

  .vffwb-mb-xl {
    margin-bottom: $mp-xl-tablet;
  }

  .vffwb-ml-xl {
    margin-left: $mp-xl-tablet;
  }

  .vffwb-mr-xl {
    margin-right: $mp-xl-tablet;
  }
}

@media only screen and (min-width: $desktop-size) {
  .vffwb-p-xl {
    padding: $mp-xl-desktop;
  }

  .vffwb-pt-xl {
    padding-top: $mp-xl-desktop;
  }

  .vffwb-pb-xl {
    padding-bottom: $mp-xl-desktop;
  }

  .vffwb-pl-xl {
    padding-left: $mp-xl-desktop;
  }

  .vffwb-pr-xl {
    padding-right: $mp-xl-desktop;
  }

  .vffwb-m-xl {
    margin: $mp-xl-desktop;
  }

  .vffwb-mt-xl {
    margin-top: $mp-xl-desktop;
  }

  .vffwb-mb-xl {
    margin-bottom: $mp-xl-desktop;
  }

  .vffwb-ml-xl {
    margin-left: $mp-xl-desktop;
  }

  .vffwb-mr-xl {
    margin-right: $mp-xl-desktop;
  }
}
// margin, padding XXL
.vffwb-p-xxl {
  padding: $mp-xxl-mobile;
}

.vffwb-pt-xxl {
  padding-top: $mp-xxl-mobile;
}

.vffwb-pb-xxl {
  padding-bottom: $mp-xxl-mobile;
}

.vffwb-pl-xxl {
  padding-left: $mp-xxl-mobile;
}

.vffwb-pr-xxl {
  padding-right: $mp-xxl-mobile;
}

.vffwb-m-xxl {
  margin: $mp-xxl-mobile;
}

.vffwb-mt-xxl {
  margin-top: $mp-xxl-mobile;
}

.vffwb-mb-xxl {
  margin-bottom: $mp-xxl-mobile;
}

.vffwb-ml-xxl {
  margin-left: $mp-xxl-mobile;
}

.vffwb-mr-xxl {
  margin-right: $mp-xxl-mobile;
}

@media only screen and (min-width: $tablet-size) {
  .vffwb-p-xxl {
    padding: $mp-xxl-tablet;
  }

  .vffwb-pt-xxl {
    padding-top: $mp-xxl-tablet;
  }

  .vffwb-pb-xxl {
    padding-bottom: $mp-xxl-tablet;
  }

  .vffwb-pl-xxl {
    padding-left: $mp-xxl-tablet;
  }

  .vffwb-pr-xxl {
    padding-right: $mp-xxl-tablet;
  }

  .vffwb-m-xxl {
    margin: $mp-xxl-tablet;
  }

  .vffwb-mt-xxl {
    margin-top: $mp-xxl-tablet;
  }

  .vffwb-mb-xxl {
    margin-bottom: $mp-xxl-tablet;
  }

  .vffwb-ml-xxl {
    margin-left: $mp-xxl-tablet;
  }

  .vffwb-mr-xxl {
    margin-right: $mp-xxl-tablet;
  }
}

@media only screen and (min-width: $desktop-size) {
  .vffwb-p-xxl {
    padding: $mp-xxl-desktop;
  }

  .vffwb-pt-xxl {
    padding-top: $mp-xxl-desktop;
  }

  .vffwb-pb-xxl {
    padding-bottom: $mp-xxl-desktop;
  }

  .vffwb-pl-xxl {
    padding-left: $mp-xxl-desktop;
  }

  .vffwb-pr-xxl {
    padding-right: $mp-xxl-desktop;
  }

  .vffwb-m-xxl {
    margin: $mp-xxl-desktop;
  }

  .vffwb-mt-xxl {
    margin-top: $mp-xxl-desktop;
  }

  .vffwb-mb-xxl {
    margin-bottom: $mp-xxl-desktop;
  }

  .vffwb-ml-xxl {
    margin-left: $mp-xxl-desktop;
  }

  .vffwb-mr-xxl {
    margin-right: $mp-xxl-desktop;
  }
}
