body {
  font-family: $font-family-regular;
  font-size: 1rem;
  line-height: 1.5;
  color: #222;
}

.vffwb-justify-content-center {
  justify-content: center!important;
}

h1,
.vffwb-h1 {
  text-align: center;
  font-size: 4.8rem;
  line-height: 1;

  margin-bottom: 20px; // margin?

  @media only screen and (min-width: $tablet-size) {
    font-size: 8rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 12rem;
  }
}

h2,
.vffwb-h2 {
  font-size: 3.8rem;
  line-height: 1;

  @media only screen and (min-width: $tablet-size) {
    font-size: 5.6rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 8rem;
  }
}

h3,
.vffwb-h3 {
  font-size: 3.2rem;
  line-height: 1.1;

  @media only screen and (min-width: $tablet-size) {
    font-size: 4.4rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 6rem;
  }
}

h4,
.vffwb-h4 {
  font-size: 2.8rem;
  line-height: 1.125;

  @media only screen and (min-width: $tablet-size) {
    font-size: 3.6rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 4.8rem;
  }
}

h5,
.vffwb-h5 {
  font-size: 2.4rem;
  line-height: 1.175;

  @media only screen and (min-width: $tablet-size) {
    font-size: 2.8rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 3.4rem;
  }
}


.vffwb-text-xs {
  font-size: 1rem;
  line-height: 1.2rem;
}

.vffwb-text-s,
small {
  font-size: 1.2rem;
}

.vffwb-text-m {
  font-size: 1.6rem;
}

.vffwb-text-l,
big {
  font-size: 1.8rem;

  @media only screen and (min-width: $tablet-size) {
    font-size: 2.2rem;
  }
}

.vffwb-text-xl {
  font-size: 2.2rem;

  @media only screen and (min-width: $tablet-size) {
    font-size: 2.4rem;
  }

  @media only screen and (min-width: $desktop-size) {
    font-size: 2.6rem;
  }
}
